/** @jsx jsx */

import React, { useMemo } from 'react'
import { css, jsx } from '@emotion/react'

import { ArticlesItem } from 'src/components/Articles/ArticlesItem'
import { ArticlesPagination } from 'src/components/Articles/ArticlesPagination'
import { Container } from 'src/components/common/Container'
import type { PageArticlesContext } from 'src/templates/articles'
import { Squidex_ArticleResultDto } from 'src/types/gatsby-types'
import { graphql } from 'gatsby'
import { headingPrimary } from 'src/components/common/Typography'
import { motion } from 'framer-motion'
import { mq } from 'src/utils/mq'

type ArticlesData = {
  squidex: {
    queryArticleContentsWithTotal: Squidex_ArticleResultDto
  }
}

const wrapper = css`
  padding-left: 16px;
  padding-right: 16px;
`

const titleStyles = css`
  ${headingPrimary};
  text-align: center;
  margin: 32px 0 64px;

  ${mq.md} {
    span {
      display: inline-block;
      border-bottom: 4px solid #000000 !important;
      padding: 6px;
    }
  }
`

const listStyles = css``

type Props = {
  data: ArticlesData
  pageContext: PageArticlesContext
}

export const Articles: React.FC<Props> = ({ data, pageContext }) => {
  if (!data?.squidex?.queryArticleContentsWithTotal?.items?.length) {
    throw new Error('No data for Articels list')
  }
  const total = data?.squidex?.queryArticleContentsWithTotal?.total || 0
  let totalPages = 0

  if (total > pageContext.perPage) {
    totalPages = Math.ceil(total / pageContext.perPage)
  }

  return (
    <Container css={wrapper}>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.4 }}
      >
        <h1 css={titleStyles}>
          <span>{pageContext.title}</span>
        </h1>
      </motion.div>

      <div css={listStyles}>
        {data.squidex.queryArticleContentsWithTotal.items.map((item) => (
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.4 }}
            key={item.id}
          >
            <ArticlesItem data={item} />
          </motion.div>
        ))}
      </div>
      <ArticlesPagination
        total={totalPages}
        current={pageContext.skip / pageContext.perPage + 1}
        pathPrefix={pageContext.pathPrefix}
      />
    </Container>
  )
}

export const query = graphql`
  fragment PreviewImage on Squidex_Asset {
    url
    id
    slug
    imageFile {
      childImageSharp {
        gatsbyImageData(width: 628, quality: 100)
      }
    }
  }
`
