import { PageProps, graphql } from 'gatsby'

import { Articles } from 'src/components/Articles/Articles'
import { Layout } from 'src/components/Layout/Layout'
import React from 'react'
import { Squidex_ArticleResultDto } from 'src/types/gatsby-types'

export type ArticlesData = {
  squidex: {
    queryArticleContentsWithTotal: Squidex_ArticleResultDto
  }
}

export type PageArticlesContext = {
  skip: number
  perPage: number
  title: string
  filter: string
  pathPrefix: string
}

const ArticlesTemplate: React.FC<
  PageProps<ArticlesData, PageArticlesContext>
> = ({ data, pageContext }) => {
  const page = pageContext.skip / pageContext.perPage
  return (
    <Layout
      title={`${pageContext.title} ${
        page > 0 ? `#${page + 1}` : ''
      } | RabbitPeepers`}
    >
      <Articles data={data} pageContext={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query ArticlesQuery($skip: Int!, $perPage: Int!, $filter: String!) {
    squidex {
      queryArticleContentsWithTotal(
        orderby: "created desc"
        top: $perPage
        skip: $skip
        filter: $filter
      ) {
        total
        items {
          id
          created
          flatData {
            title
            isNew
            typeOfContent
            displayPublishDate
            description
            preview {
              ...PreviewImage
            }
          }
        }
      }
    }
  }
`

export default ArticlesTemplate
