/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { Container } from 'src/components/common/Container'
import { Link } from 'gatsby'
import React from 'react'
import { RoundedButton } from 'src/components/common/RoundedButton'
import { mq } from 'src/utils/mq'

type Props = {
  total: number
  current: number
  pathPrefix: string
}

const wrapper = css`
  display: flex;
  justify-content: space-between;
`

const itemStyles = css`
  margin: 0 8px 0 0;
  display: inline-block;
  text-decoration: none;

  &:last-of-type {
    margin: 0;
  }
`

const arrowStyles = css`
  width: 100px;
  display: flex;
  justify-content: space-between;

  ${mq.lg} {
    width: 120px;
  }
`

export const ArticlesPagination: React.FC<Props> = ({
  total,
  current,
  pathPrefix,
}) => {
  if (!total) {
    return null
  }
  const range = Array.from({ length: total })
    .fill(0)
    .map((_, index) => index + 1)

  const hasPrevArrow = current > 1
  const hasNextArrow = current < total

  const prevNumber = current > 2 ? `${current - 1}` : ''

  return (
    <Container style={{ padding: '0 8px' }}>
      <div css={wrapper}>
        <div>
          {range.map((i) => (
            <Link
              to={`/blog${pathPrefix ? '/' : ''}${pathPrefix}/${
                i === 1 ? '' : `${i}`
              }`}
              css={itemStyles}
              key={i}
            >
              <RoundedButton
                color={i === current ? 'normal' : 'inactive'}
                variant="text"
              >
                {i}
              </RoundedButton>
            </Link>
          ))}
        </div>
        <div css={arrowStyles}>
          {hasPrevArrow ? (
            <Link
              to={`/blog${pathPrefix ? '/' : ''}${pathPrefix}/${prevNumber}`}
              style={{ textDecoration: 'none' }}
            >
              <RoundedButton variant="arrow-left" />
            </Link>
          ) : (
            <RoundedButton variant="arrow-left" color="disabled" />
          )}

          {hasNextArrow ? (
            <Link
              to={`/blog${pathPrefix ? '/' : ''}${pathPrefix}/${current + 1}`}
              style={{ textDecoration: 'none' }}
            >
              <RoundedButton variant="arrow-right" />
            </Link>
          ) : (
            <RoundedButton variant="arrow-right" color="disabled" />
          )}
        </div>
      </div>
    </Container>
  )
}
