/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import React from 'react'
import { theme } from 'src/utils/theme'

const badgeStyles = css`
  display: inline-block;
  background: ${theme.colors.active};
  color: white;
  padding: 4px 8px;
  font-size: .75em;
  line-height: 1em;
  font-weight: bold;
  top: -6px;
  left: -10px;
  margin-right: .5em;
`

export const ArticleNewBadge: React.FC = () => {
  return <span css={badgeStyles}>NEW!</span>
}
